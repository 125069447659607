export const ComponentIds = {
  RootBox: '#rootBox',
  ProgressBar: '#progressBar',
  HeadlineBox: '#headlineBox',
  DonatedAmountText: '#donatedAmountText',
  GoalAmountText: '#goalAmountText',
  BarAndMetricsBox: '#barAndMetricsBox',
  MetricsBox: '#metricsBox',
  PercentageText: '#percentageText',
  TimeLeftText: '#daysLeftText',
  TimeLeftBox: '#daysLeftBox',
  DonationCountText: '#donationCountText',
  DonationCountBox: '#donationCountBox',
} as const;
