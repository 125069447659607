import { ComponentRef, EditorSDK, EventType } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import {
  ElementsParentWidget,
  ElementsScope,
  openShowHidePanel,
} from './openShowHidePanel';

export const updatePanelContentOnViewStateChanged = async (
  editorSDK: EditorSDK,
  ref: ComponentRef,
  flowAPI: FlowAPI,
  scope: ElementsScope,
  parentWidget: ElementsParentWidget,
) => {
  editorSDK.addEventListener(
    EventType.viewStateChanged,
    () => {
      openShowHidePanel(editorSDK, ref, flowAPI, scope, parentWidget);
    },
    { once: true },
  );
};
